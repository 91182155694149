<template>
  <div class="ma-4" style="height:100%">
    <admin-header></admin-header>
    <v-alert v-if="days.length === 0" type="error">This term has not been set up for juries. Please initiate the setup for the selected term.</v-alert>
    <v-row v-else>
      <v-col>
        <v-card>
          <v-card-title>Dates</v-card-title>
          <v-list>
            <v-list-item v-for="date in days" :key="date">
              <v-list-item-title>{{ stringFormatDate(date, true, true) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Instruments</v-card-title>
          <v-list>
            <v-list-item v-for="{ name } in instruments" :key="name">
              <v-list-item-title>{{ name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Instructors</v-card-title>
          <v-list>
            <v-list-item v-for="{ name } in instructors" :key="name">
              <v-list-item-title>{{ name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Accompansists</v-card-title>
          <v-list>
            <v-list-item v-for="{ name } in accompanists" :key="name">
              <v-list-item-title>{{ name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'
export default {
  components: {
    AdminHeader: () => import('@/components/student/music/adminHeader')
  },
  setup (props, { root }) {
    const term = computed({
      get: () => root.$store.state.student.music.admin.term,
      set: (term) => root.$store.commit('student/setMusicAdmin', { term })
    })
    const days = ref([])
    const instruments = ref([])
    const instructors = ref([])
    const accompanists = ref([])

    watch(term, async () => {
      const { data } = await root.$feathers.service('student/music/jury-setup').find({ query: { term: term.value } })
      if (data.length > 0) {
        days.value = data[0].days
        instruments.value = data[0].instruments
        instructors.value = data[0].instructors
        accompanists.value = data[0].accompanists
      } else {
        days.value = []
        instruments.value = []
        instructors.value = []
        accompanists.value = []
      }
    })

    return {
      term,
      days,
      instruments,
      instructors,
      accompanists,
      stringFormatDate
    }
  }
}
</script>
